import {
  CSSProperties,
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  ActionButton,
  DefaultButton,
  FontSizes,
  Icon,
  IconButton,
  mergeStyles,
  NeutralColors,
  PrimaryButton,
  ProgressIndicator,
  SharedColors,
  Stack,
  Text,
  TextField,
} from '@fluentui/react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import {
  PLAYBOOK_INSTRUCTION_ADDED_BY_USER,
  PlaybookBuilderContentResponse,
  PlaybookMetadata,
  PlaybookResource,
  Resource,
  type PatchPlaybookRequest,
  type PatchPlaybookResponse,
  type PlaybookBuilderStructuredOutput,
  type PlaybookContent,
  type PlaybookContractInstruction,
} from '@blaw/contracts-api-schema'
import { defaultCollapsbleIconStyles } from '@components/CollapsibleItem'
import { deleteBtnStyles } from '@modules/sharedStyles'
import { StoreContext } from '@contexts/StoreContext'
import { StyledDivider } from '@baseComponents/StyledDivider'
import { parseJSON } from '@modules/utils'
import { itemClicked, useContractTaskPaneViewed } from '@modules/analytics'
import { useTranslation } from '@hooks/useTranslation'
import AddAnotherTextField from '@components/AddAnotherTextField'
import ApiClient from '@modules/ApiClient'
import BoldText from '@baseComponents/BoldText'
import ConfirmDialog from '@components/ConfirmDialog'
import ContentCard from '@components/ContentCard'
import InlineToggle from '@baseComponents/InlineToggle'
import NotificationBadge from '@components/NotificationBadge'
import ShowMore from '@components/ShowMore'
import SmallButton from '@components/SmallButton'
import StatusBadge from '@components/StatusBadge'
import StyledStack from '@components/StyledStack'
import TopNav from '@components/TopNav'
import UnstyledList from '@baseComponents/UnstyledList'
import LoadingShimmer from '@components/LoadingShimmer'
import ModalProgress from '@components/ModalProgress'
import { PlaybooksContext } from '@contexts/PlaybooksContext'
import DeletePlaybookForm from '@components/DeletePlaybookForm'

type MergedPlaybookContent = PlaybookContent & { name: string; metadata: PlaybookMetadata }
type ClauseContentKey = keyof PlaybookBuilderStructuredOutput
type PlaybookState = {
  name: string
  contractType: string
  playbookPartyRole: string
  templateName: string
  playbookContent: PlaybookContent
}
type TemplateResponse = {
  response: Resource
}

// TODO: move to api schema when integrating the frontend
type GetPlaybookResponse = {
  playbook?: PlaybookResource
  content?: PlaybookContent
  error?: string
}

const contentCardStyles: CSSProperties = { padding: '0.7em 0.7em 0 0.7em' }
const contentTitleStyles: CSSProperties = { fontSize: FontSizes.large }
const toolbarStyles: CSSProperties = { display: 'flex', alignItems: 'center', gap: '0.3em' }
const labelStyles = { marginBottom: '0.5em', display: 'block' }
const EMPTY_CLAUSE_ALTERNATE = 'None Found'

export default function EditPlaybook() {
  const { playbookId } = useParams()
  const [params] = useSearchParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { storeSessionInfo, routes } = useContext(StoreContext)
  const { setDeleteModalHidden, deleteModalHidden, deleting } = useContext(PlaybooksContext)
  const [loading, setLoading] = useState(true)
  const [hideProgress, setHideProgress] = useState(true)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')
  const [playbook, setPlaybook] = useState({} as PlaybookState)
  const [templateName, setTemplateName] = useState('')
  const [metadata, setMetadata] = useState({} as PlaybookMetadata)
  const [contractInstructions, setContractInstructions] = useState(
    [] as PlaybookContractInstruction[],
  )
  const [clauseContent, setClauseContent] = useState([] as PlaybookBuilderContentResponse)
  const [deleteInstructionDialogHidden, setDeleteInstructionDialogHidden] = useState(true)
  const [selectedInstructionIndex, setSelectedInstructionIndex] = useState(-1)
  const [lastEditedClause, setLastEditedClause] = useState<PlaybookBuilderStructuredOutput>()
  const clauseAlternatesToRemove = useRef<number[]>([])

  const pageTitle = t('page.Playbook Details.Title')
  const newCompliancePath = `/playbooks/${playbookId}/compliance/new?title=${playbook.name}&type=${playbook.contractType}&role=${playbook.playbookPartyRole}`
  const apiClient = new ApiClient(storeSessionInfo, setError)
  let originalContractInstructions: string[] = []

  useContractTaskPaneViewed({ pageTitle, eventDetails: [playbookId ?? 'N/A'] })

  useEffect(() => {
    if (!playbookId) return

    setLoading(true)
    setError('')

    loadPlaybook(playbookId)
      .then(setPlaybookState)
      .then(({ playbookData, metadata, templateIds }) => {
        setPlaybook(playbookData)
        setMetadata(metadata)

        const id = templateIds?.[0]
        if (!id) throw Error('Missing template ID')

        return apiClient.get<TemplateResponse>(routes.getResourceUrl(id))
      })
      .then(({ data }) => setTemplateName(data.response.name))
      .catch(e => {
        setError(e.message)
        console.error(e)
      })
      .finally(() => setLoading(false))
  }, [])

  if (!playbookId) throw Error('Missing playbook ID')

  if (loading)
    return (
      <Layout>
        <ProgressIndicator label={`Loading ${params.get('name') ?? ''}...`} />
        <LoadingShimmer />
      </Layout>
    )

  return (
    <Layout>
      <Stack.Item>
        <ContentCard
          title={t('page.Contracts.General Information')}
          style={contentCardStyles}
          titleStyle={contentTitleStyles}
        >
          <Metadata
            editable
            name={t('page.Playbook Builder.Playbook Name')}
            values={[playbook.name]}
            saving={saving}
            onChange={updatePlaybookName}
          />
          <Metadata name="Contract Type" values={[playbook.contractType]} />
          <Metadata name="Party Role" values={[playbook.playbookPartyRole]} />
          <Metadata name="Source Documents" values={[templateName]} style={{ marginBottom: 0 }} />
        </ContentCard>
      </Stack.Item>

      <Stack.Item>
        <ContentCard
          title={t('page.Playbook Details.Contract Instructions')}
          style={contentCardStyles}
          titleStyle={contentTitleStyles}
        >
          {contractInstructions.map((entry, i) => {
            const userAdded = entry.source === PLAYBOOK_INSTRUCTION_ADDED_BY_USER
            const toggleLabel = `Use ${userAdded ? '' : 'as'} instruction`
            const color = userAdded ? 'cyan10' : 'magenta20'
            const badge = userAdded
              ? t('page.Playbook Details.Manually added')
              : t('page.Playbook Details.Extracted from template')

            return (
              <Metadata
                editable
                deletable
                multiline
                key={`${i}-${entry.instruction}`}
                values={[entry.instruction]}
                saving={saving}
                name={`${typeOfInstruction(entry.added)} ${i + 1}`}
                unsaved={
                  !entry.instruction || !originalContractInstructions.includes(entry.instruction)
                }
                dialogHiddenDefault={!!entry.instruction || saving}
                onRemove={() => confirmRemoveContractInstruction(i)}
                onChange={v => updateContractInstructions(i, v)}
                onDismiss={([v]) =>
                  !v?.trim() && selectedInstructionIndex >= 0 && removeLastContractInstruction()
                }
              >
                <StatusBadge
                  status={badge}
                  badgeColor={SharedColors[color]}
                  style={{ margin: '0.3em 0 0.5em 0' }}
                />
                <ShowMore
                  content={entry.instruction}
                  style={{ marginBottom: '0.5em', display: 'inline-block' }}
                />
                <InlineToggle
                  label={toggleLabel}
                  checked={entry.added}
                  disabled={saving}
                  onChange={async () => {
                    entry.added = !entry.added
                    await submit({
                      metadata,
                      name: playbook.name,
                      clauses: clauseContent,
                      contract_instructions: contractInstructions,
                    })
                    setContractInstructions([...contractInstructions])
                  }}
                />
                {/* TODO: template_citation might be needed for post MVP */}
                {/* {entry.template_citation?.trim() && (
                  <UnstyledList style={{ margin: '0.3em 0' }}>
                    <CollapsibleItem
                      key={i}
                      item={{
                        key: entry.template_citation,
                        children: entry.template_citation,
                        active: false,
                      }}
                      itemHeader={() => 'Template Citation'}
                      itemContent={() => (
                        <TemplateCitation content={entry.template_citation ?? ''} />
                      )}
                    />
                  </UnstyledList>
                )} */}
                <StyledDivider />
              </Metadata>
            )
          })}

          <Stack.Item style={{ display: 'flex', justifyContent: 'end' }}>
            <ActionButton
              iconProps={{ iconName: 'Add' }}
              style={{ marginTop: '0.3em' }}
              onClick={() =>
                setContractInstructions([...contractInstructions, buildManuallyAddedInstruction()])
              }
            >
              Add Custom Instruction
            </ActionButton>
          </Stack.Item>
        </ContentCard>
      </Stack.Item>

      <Stack.Item>
        <ContentCard
          title={t('page.Playbook Details.Clause Content')}
          style={contentCardStyles}
          titleStyle={contentTitleStyles}
        >
          <UnstyledList>
            {clauseContent.map((clause, i) => (
              <Metadata
                editable
                multiline
                key={i + JSON.stringify(clause)}
                name={clause.clause_title}
                saving={saving}
                editDialogTitle={t('page.Playbook Details.Clause Content')}
                showContentDivider={i < clauseContent.length - 1}
                collapsedContent={
                  <ClauseContent
                    key={i}
                    clause={clause}
                    highlight={lastEditedClause?.clause_title === clause.clause_title}
                  />
                }
                defaultCollapsed={
                  !lastEditedClause || clause.clause_title !== lastEditedClause.clause_title
                }
                onChange={values => updateClause(clause, clauseAlternatesToRemove, values)}
                CustomEditor={({ setValues }) => (
                  <EditClauseContent
                    key={clause.clause_title}
                    clause={clause}
                    setValues={setValues}
                    setLastEditedClause={setLastEditedClause}
                    onRemoveClauseAlternate={i => clauseAlternatesToRemove.current.push(i)}
                  />
                )}
                innerStyle={{ padding: '0.3em 0 0.7em 0' }}
              >
                {findAlternateClauses(clause).length > 0 && <ClauseContentHeader clause={clause} />}
              </Metadata>
            ))}
          </UnstyledList>
        </ContentCard>
      </Stack.Item>

      <Stack.Item style={toolbarStyles}>
        <PrimaryButton onClick={() => navigate(newCompliancePath)} style={{ flexGrow: 1 }}>
          {t('button.Check Compliance')}
        </PrimaryButton>

        <DefaultButton onClick={() => navigate('/playbooks')} style={{ flexGrow: 1 }}>
          {t('button.Cancel')}
        </DefaultButton>

        <IconButton
          title={`${t('button.Delete')} ${playbook.name}`}
          disabled={saving || deleting}
          iconProps={{ iconName: 'Delete' }}
          onClick={() => setDeleteModalHidden(false)}
          style={{ color: SharedColors.red20 }}
        />
        <DeletePlaybookForm
          id={playbookId}
          name={playbook.name}
          hidden={deleteModalHidden}
          setDeleteModalHidden={setDeleteModalHidden}
          onDeleted={() => navigate(`/playbooks`)}
        />
      </Stack.Item>

      <ConfirmDialog
        hidden={deleteInstructionDialogHidden}
        title={t('label.Confirm Delete')}
        confirm={`${t('button.Delete')} ${t('label.Instruction')}`}
        btnStyles={deleteBtnStyles}
        onDismiss={() => setDeleteInstructionDialogHidden(true)}
        onConfirm={() => removeContractInstruction(selectedInstructionIndex)}
      >
        <BoldText>{selectedInstructionLabel()}</BoldText>
        <ShowMore
          content={contractInstructions[selectedInstructionIndex]?.instruction ?? ''}
          style={{ display: 'block', marginTop: '0.3em' }}
        />
      </ConfirmDialog>

      <ModalProgress
        title={error ? 'An error occurred' : 'Updating playbook configuration'}
        hidden={hideProgress}
        error={error}
        cancelBtn={error ? 'Close' : false}
        onDismiss={() => location.reload()}
      />
    </Layout>
  )

  function Layout({ children }: PropsWithChildren) {
    return (
      <>
        <TopNav title={pageTitle} prevPath="#/playbooks" />
        <StyledStack>{children}</StyledStack>
      </>
    )
  }

  async function loadPlaybook(id: string) {
    const { data } = await apiClient.get<GetPlaybookResponse>(routes.playbookUrl(id))
    return data
  }

  function setPlaybookState(playbookResponse: GetPlaybookResponse) {
    const { playbook, content } = playbookResponse
    if (!(playbook && content)) throw Error('Failed to load Playbook')

    const parsedContent = parseJSON<PlaybookContent>(content)
    const name = playbook.name
    const contractType = playbook.metadata.userMetadata.contract_type
    const playbookPartyRole = playbook.metadata.userMetadata.playbook_party_role
    const clauses = parsedContent.clauses
    const contract_instructions = parsedContent.contract_instructions
    originalContractInstructions = contract_instructions.map(({ instruction: i }) => i)
    const playbookData: PlaybookState = {
      name,
      contractType,
      templateName,
      playbookPartyRole,
      playbookContent: { clauses, contract_instructions },
    }

    setPlaybook(playbookData)
    setClauseContent(clauses)
    setContractInstructions(contract_instructions)

    return {
      playbookData,
      metadata: playbook.metadata,
      templateIds: playbook.metadata.userMetadata.playbook_template_ids,
    }
  }

  async function updatePlaybookName(values: string[]) {
    const name = values[0] ?? ''

    await submit({
      name,
      metadata,
      clauses: clauseContent,
      contract_instructions: contractInstructions,
    })
    setPlaybook({ ...playbook, name })
  }

  async function updateContractInstructions(i: number, values: string[]) {
    const newValues = structuredClone(contractInstructions)
    const original = contractInstructions[i]
    const updated = newValues[i]
    const value = (values[0] ?? '').trim()

    updated.added = true
    updated.instruction = value
    if (original.instruction !== value) updated.source = PLAYBOOK_INSTRUCTION_ADDED_BY_USER

    await submit({
      ...playbook,
      metadata,
      clauses: clauseContent,
      contract_instructions: newValues,
    })
    setContractInstructions(newValues)
  }

  async function confirmRemoveContractInstruction(i: number) {
    setSelectedInstructionIndex(i)
    setDeleteInstructionDialogHidden(false)
  }

  async function removeContractInstruction(i: number) {
    const newValues = withoutContractInstruction(i)

    setDeleteInstructionDialogHidden(true)
    await submit({
      ...playbook,
      metadata,
      clauses: clauseContent,
      contract_instructions: newValues,
    })
    setContractInstructions(newValues)
  }

  function removeLastContractInstruction() {
    const newValues = withoutContractInstruction(contractInstructions.length - 1)
    setDeleteInstructionDialogHidden(true)
    setContractInstructions(newValues)
  }

  function withoutContractInstruction(i: number) {
    const newValues = structuredClone(contractInstructions)
    newValues.splice(i, 1)
    return newValues
  }

  async function updateClause(
    clause: PlaybookBuilderStructuredOutput,
    clauseAlternatesToRemove: MutableRefObject<number[]>,
    values: string[],
  ) {
    const alternates = findAlternateClauses(clause)
    const toRemove = clauseAlternatesToRemove.current

    if (toRemove.length) {
      toRemove.map(i => (clause[`alternate_${i + 1}` as ClauseContentKey] = EMPTY_CLAUSE_ALTERNATE))
      // handle edgecase where a user adds 2 alternates, then removes the first one, then tries
      // to remove the 2nd one. The index parameter won't match up with the alternate's key name
      if (alternates.length === 1 && toRemove.length === 1) {
        clause.alternate_2 = EMPTY_CLAUSE_ALTERNATE
      }
      clauseAlternatesToRemove.current = []
    } else {
      values.map((v, i) => (clause[`alternate_${i + 1}` as ClauseContentKey] = v ?? ''))
    }

    await submit({
      ...playbook,
      metadata,
      clauses: clauseContent,
      contract_instructions: contractInstructions,
    })
    setClauseContent([...clauseContent])
  }

  async function submit({ name, metadata, clauses, contract_instructions }: MergedPlaybookContent) {
    if (!playbookId) throw Error(`Missing playbookId parameter`)

    itemClicked({
      pageTitle: pageTitle,
      itemClicked: t('page.Playbook Details.Save Playbook'),
      itemLocation: 'Taskpane',
      itemType: 'Form Submit',
      isLoggedIn: true,
    })

    try {
      const apiClient = new ApiClient(storeSessionInfo, setError)
      const content = { clauses, contract_instructions }
      setSaving(true)
      setError('')
      setHideProgress(false)

      const payload: PatchPlaybookRequest = {
        content,
        playbook: { name, metadata },
      }
      await apiClient.patch<PatchPlaybookResponse>(routes.playbookUrl(playbookId), payload)

      setHideProgress(true)
    } catch (e) {
      console.error('e', e)
      setError((e as Error).message)
    } finally {
      setSaving(false)
    }
  }

  function typeOfInstruction(userAdded: boolean | undefined) {
    return t(`label.${userAdded ? 'Instruction' : 'Annotation'}`)
  }

  function selectedInstructionLabel() {
    const { added } = contractInstructions[selectedInstructionIndex] ?? {}
    return `${typeOfInstruction(added)} ${selectedInstructionIndex + 1}`
  }
}

type MetadataProps = PropsWithChildren & {
  name: string
  values?: string[]
  saving?: boolean
  unsaved?: boolean
  editable?: boolean
  deletable?: boolean
  multiline?: boolean
  editDialogTitle?: string
  onChange?: (v: string[]) => Promise<void>
  onRemove?: () => void
  onDismiss?: (v: string[]) => void
  CustomEditor?: (props: EditorProps) => JSX.Element
  dialogHiddenDefault?: boolean
  collapsedContent?: string | ReactNode
  defaultCollapsed?: boolean
  showContentDivider?: boolean
  style?: CSSProperties
  innerStyle?: CSSProperties
}

function Metadata(props: MetadataProps) {
  const { t } = useTranslation()
  const [dialogHidden, setDialogHidden] = useState(props.dialogHiddenDefault)
  const [collapsed, setCollapsed] = useState(props.defaultCollapsed)
  const title = `${t(`button.${props.unsaved ? 'Add' : 'Edit'}`)} ${props.name}`
  const collapseIcon = collapsed ? 'ChevronRight' : 'ChevronDown'
  const cursor = props.collapsedContent ? 'pointer' : 'inherit'
  const collapsibleBoxClass = mergeStyles({
    ':hover, :active': { background: props.collapsedContent && NeutralColors.gray30 },
  })
  const borderBottom = props.showContentDivider ? `1px solid ${SharedColors.gray10}` : ''

  return (
    <Stack style={props.style}>
      <Stack
        horizontal
        onClick={() => setCollapsed(!collapsed)}
        style={{ alignItems: 'baseline', cursor, borderBottom, ...props.innerStyle }}
        className={collapsibleBoxClass}
      >
        {props.collapsedContent && (
          <Icon
            iconName={collapseIcon}
            onClick={() => setCollapsed(!collapsed)}
            style={defaultCollapsbleIconStyles}
          />
        )}
        <div style={{ width: '100%' }}>
          <Stack horizontal style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <BoldText>{props.name}</BoldText>
            <Stack horizontal>
              {props.deletable && (
                <IconButton
                  iconProps={{ iconName: 'Delete' }}
                  onClick={props.onRemove}
                  style={{ color: SharedColors.red20 }}
                />
              )}
              <EditButton
                title={title}
                show={props.editable}
                onClick={() => setDialogHidden(false)}
              />
            </Stack>
          </Stack>
          {props.children ?? props.values}
        </div>
      </Stack>

      {props.collapsedContent && !collapsed && (
        <Stack style={{ marginBottom: '0.3em' }}>{props.collapsedContent}</Stack>
      )}
      {/* {props.showContentDivider && <StyledDivider />} */}

      <EditDialog
        title={props.editDialogTitle ?? title}
        label={props.name}
        multiline={props.multiline}
        values={props.values ?? ['']}
        saving={props.saving}
        hidden={dialogHidden}
        setHidden={setDialogHidden}
        onChange={props.onChange}
        onDismiss={props.onDismiss}
        CustomEditor={props.CustomEditor}
      />
    </Stack>
  )
}

type TemplateCitationProps = {
  content: string
}

// TODO: template citation might be needed for post MVP
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function TemplateCitation({ content }: TemplateCitationProps) {
  return (
    <>
      <div style={{ padding: '0.7em', backgroundColor: NeutralColors.gray20 }}>
        <ShowMore content={content} />
      </div>
      <StyledDivider />
    </>
  )
}

type ClauseContentProps = {
  clause: PlaybookBuilderStructuredOutput
  highlight?: boolean
}

function ClauseContent({ clause, highlight }: ClauseContentProps) {
  const { t } = useTranslation()
  const alternates = findAlternateClauses(clause)

  return (
    <Stack style={{ gap: '0.5em', paddingLeft: '1.7em' }}>
      <Stack.Item style={{ padding: '0.3em' }}>
        <BoldText style={labelStyles}>Preferred Language</BoldText>
        <ShowMore
          content={clause.sample_standard_language_acceptable_position}
          style={{ display: 'block' }}
        />
      </Stack.Item>
      <Stack.Item style={{ padding: '0.3em' }}>
        <BoldText style={labelStyles}>Clause Summary</BoldText>
        <ShowMore content={clause.acceptable_position} style={{ display: 'block' }} />
      </Stack.Item>
      <Stack.Item style={{ padding: '0.3em' }}>
        <UnstyledList>
          {alternates.map(([_, alternate], i) => {
            return (
              <li
                key={i}
                className={highlight ? 'highlight' : ''}
                style={{ marginBottom: '0.8em' }}
              >
                <BoldText style={labelStyles}>
                  {t('page.Playbook Details.Alternate Clause')} {i + 1}
                </BoldText>
                <ShowMore content={alternate} style={{ display: 'block' }} />
              </li>
            )
          })}
        </UnstyledList>
      </Stack.Item>
    </Stack>
  )
}

type EditClauseContentProps = {
  clause: PlaybookBuilderStructuredOutput
  setValues: Dispatch<SetStateAction<string[]>>
  setLastEditedClause: Dispatch<SetStateAction<PlaybookBuilderStructuredOutput | undefined>>
  onRemoveClauseAlternate: (i: number) => void
}

function EditClauseContent(props: EditClauseContentProps) {
  const { t } = useTranslation()
  const edited = useRef(false)
  const values = findAlternateClauses(props.clause).map(([_, v]) => v)

  // set the LastEditedClause when user is done editing and this component unmounts
  useEffect(
    () => () => {
      edited.current && props.setLastEditedClause(props.clause)
    },
    [],
  )

  return (
    <Stack style={{ gap: '1em' }}>
      <Stack.Item>
        <BoldText style={labelStyles}>Clause Title</BoldText>
        <ShowMore content={props.clause.clause_title} />
      </Stack.Item>
      <Stack.Item>
        <BoldText style={labelStyles}>Preferred Language</BoldText>
        <ShowMore content={props.clause.sample_standard_language_acceptable_position} />
      </Stack.Item>
      <Stack.Item>
        <BoldText style={labelStyles}>Clause Summary</BoldText>
        <ShowMore content={props.clause.acceptable_position} />
      </Stack.Item>
      <StyledDivider style={{ marginBottom: 0 }} />

      <AddAnotherTextField
        multiline
        autoAdjustHeight
        maxFields={2}
        values={values}
        labelPrefix={t('page.Playbook Details.Alternative Clause')}
        addBtnLabel={t('page.Playbook Details.Add Alternative Clause')}
        btnStyles={{ alignSelf: 'end' }}
        onValueChange={values => {
          props.setValues(values)
          edited.current = true
        }}
        onRemove={i => props.onRemoveClauseAlternate(i)}
      />
    </Stack>
  )
}

type ClauseContentHeaderProps = {
  clause: PlaybookBuilderStructuredOutput
}

function ClauseContentHeader({ clause }: ClauseContentHeaderProps) {
  const { t } = useTranslation()

  return (
    <div>
      <Text>{t('page.Playbook Details.Alternative Clauses')}</Text>
      <NotificationBadge
        style={{
          marginLeft: '0.3em',
          backgroundColor: SharedColors.cyan10,
        }}
      >
        {findAlternateClauses(clause).length}
      </NotificationBadge>
    </div>
  )
}

type EditDialogProps = {
  title: string
  label: string
  values: string[]
  setHidden: Dispatch<SetStateAction<boolean | undefined>>
  onChange?: (v: string[]) => Promise<void>
  onDismiss?: (v: string[]) => void
  CustomEditor?: (props: EditorProps) => JSX.Element
  multiline?: boolean
  hidden?: boolean
  saving?: boolean
}
type EditorProps = {
  values: string[]
  setValues: Dispatch<SetStateAction<string[]>>
  onChange?: (v: string[]) => Promise<boolean>
}

function EditDialog(props: EditDialogProps) {
  const { t } = useTranslation()
  const [internalValue, setInternalValue] = useState(props.values)
  const Editor = props.CustomEditor ? props.CustomEditor : DefaultEditor

  return (
    <ConfirmDialog
      title={props.title}
      hidden={props.hidden}
      disableBtn={props.saving}
      confirm={t('button.Apply Changes')}
      onConfirm={async () => {
        await props.onChange?.(internalValue)
        props.setHidden(true)
      }}
      onDismiss={() => {
        props.onDismiss?.(internalValue)
        props.setHidden(true)
      }}
    >
      <Editor values={internalValue} setValues={setInternalValue} />
    </ConfirmDialog>
  )

  function DefaultEditor({ values, setValues, onChange }: EditorProps) {
    const [internalValue, setInternalValue] = useState(values)

    return (
      <Stack.Item>
        <TextField
          autoFocus
          label={props.label}
          value={internalValue[0]}
          disabled={props.saving}
          multiline={props.multiline}
          autoAdjustHeight={props.multiline}
          onChange={(_e, newValue) => {
            setInternalValue([newValue ?? ''])
            onChange?.([newValue ?? ''])
          }}
          onBlur={() => setValues(internalValue)}
        />
      </Stack.Item>
    )
  }
}

type EditButtonProps = {
  title: string
  onClick: () => void
  show?: boolean
}

function EditButton({ title, onClick, show }: EditButtonProps) {
  return (
    <SmallButton
      title={title}
      onClick={onClick}
      iconProps={{ iconName: 'Edit' }}
      style={{ visibility: show ? 'visible' : 'hidden' }}
      ariaHidden={!show}
    />
  )
}

function findAlternateClauses(clause: PlaybookBuilderStructuredOutput) {
  return Object.entries(clause).filter(
    ([key, val]) =>
      key.startsWith('alternate') &&
      val?.trim() &&
      val.toLowerCase() !== EMPTY_CLAUSE_ALTERNATE.toLowerCase(),
  )
}

function buildManuallyAddedInstruction(): PlaybookContractInstruction {
  return {
    instruction: '',
    template_citation: '',
    source: PLAYBOOK_INSTRUCTION_ADDED_BY_USER,
    added: true,
  }
}
