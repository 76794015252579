import { CSSProperties } from 'react'
import { useNavigate } from 'react-router'
import {
  FontSizes,
  FontWeights,
  Label,
  Pivot,
  PivotItem,
  SharedColors,
  Stack,
} from '@fluentui/react'
import { useLocation } from 'react-router-dom'

import TopNav from '@components/TopNav'
import StyledStack from '@components/StyledStack'
import { useContractTaskPaneViewed } from '@modules/analytics'
import ContractTitle from '@baseComponents/ContractTitle'
import { useTranslation } from '@hooks/useTranslation'
import UnstyledList from '@baseComponents/UnstyledList'
import CollapsibleItem from '@components/CollapsibleItem'
import BoldText from '@baseComponents/BoldText'
import { titleize } from '@modules/utils'
import ClauseIssueContent from '@components/ClauseIssueContent'
import TextBadge from '@components/TextBadge'
import Box from '@baseComponents/Box'
import LongTextWithLabel from '@components/LongTextWithLabel'
import { PlaybookComplianceResult, ClauseIssue } from '@blaw/contracts-api-schema'
import { StyledDivider } from '@baseComponents/StyledDivider'

const pageTitle = 'Compliance'
const badgeStyle: CSSProperties = {
  fontSize: FontSizes.size12,
  backgroundColor: SharedColors.gray10,
  borderColor: SharedColors.gray10,
  whiteSpace: 'nowrap',
  display: 'block',
  marginTop: '0.5em',
}
const issueBadgeStyleMap: Record<string, CSSProperties> = {
  'Instruction Issue': {
    ...badgeStyle,
    backgroundColor: SharedColors.cyan10,
    borderColor: SharedColors.cyan20,
  },
  'Clause Language Issue': {
    ...badgeStyle,
    backgroundColor: SharedColors.magenta10,
    borderColor: SharedColors.magenta20,
  },
  Missing: {
    ...badgeStyle,
    backgroundColor: SharedColors.red10,
    borderColor: SharedColors.pinkRed10,
  },
}

export default function PlaybooksComplianceSummary() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const subtitle = t('page.Playbooks Compliance Summary.subtitle')
  const location = useLocation()
  const playbookComplianceResult: PlaybookComplianceResult = location.state
  const {
    non_compliant_clauses: issues,
    compliant_clauses: compliant,
    not_reviewed: notReviewed,
  } = playbookComplianceResult

  useContractTaskPaneViewed({ pageTitle: `${pageTitle} ${subtitle}` })

  return (
    <>
      <TopNav title={pageTitle} prevPath={() => navigate(-1)} showAIGeneratedBadge />
      <StyledStack style={{ paddingBottom: '3em' }}>
        <ContractTitle title={subtitle} style={{ fontWeight: FontWeights.bold }} />
        {renderContent()}
      </StyledStack>
    </>
  )

  function renderContent() {
    return (
      <>
        <Stack.Item style={{ margin: '0 1em 1em 0' }}>
          {t('page.Playbooks Compliance Summary.blurb')}
        </Stack.Item>
        <Stack.Item style={{ margin: '0 1em 1em 0' }}>
          {t('page.Playbooks Compliance Summary.blurb2')}
        </Stack.Item>
        <Stack.Item>
          <Pivot
            aria-label={pageTitle}
            styles={{
              root: { display: 'flex', justifyContent: 'space-between', marginBottom: '1.2em' },
            }}
          >
            {issues.length > 0 && (
              <PivotItem
                itemKey={'0'}
                headerText="Issues"
                style={{ display: 'flex', gap: '0.3em', flexDirection: 'column' }}
              >
                <Stack.Item>
                  {renderIssuesList(
                    'Primary Issues',
                    issues.filter(c => c.important_clause),
                  )}
                </Stack.Item>
                <Stack.Item>
                  {renderIssuesList(
                    'Secondary Issues',
                    issues.filter(c => !c.important_clause),
                  )}
                </Stack.Item>
              </PivotItem>
            )}
            {compliant.length > 0 && (
              <PivotItem itemKey={'1'} headerText="Compliant">
                <Stack.Item>{renderCompliantList()}</Stack.Item>
              </PivotItem>
            )}
            {notReviewed.length > 0 && (
              <PivotItem itemKey={'2'} headerText="Not in Playbook">
                <Stack.Item>{renderNotReviewedList()}</Stack.Item>
              </PivotItem>
            )}
          </Pivot>
        </Stack.Item>
      </>
    )
  }

  function renderIssuesList(title: string, issues: ClauseIssue[]) {
    if (!issues.length) return null

    return (
      <Box>
        <h3 style={{ margin: '0 0 0.3em' }}>{title}</h3>
        <UnstyledList>
          {issues.map((item, idx) => {
            const isLastItem = idx === issues.length - 1
            return (
              <CollapsibleItem
                key={idx.toString()}
                item={{ ...item, key: idx.toString(), active: false }}
                itemHeader={() => issueHeader(item)}
                itemContent={() => <ClauseIssueContent issue={item} />}
                iconStyles={{ marginBottom: '2em', paddingLeft: 0 }}
                listItemStyles={isLastItem ? { borderBottom: 'none' } : undefined}
              />
            )
          })}
        </UnstyledList>
      </Box>
    )
  }

  function renderCompliantList() {
    return (
      <UnstyledList style={{ padding: '0 0.5em 0' }}>
        {compliant.map(({ clause_title, explanation }, idx) => {
          const isLastItem = idx === compliant.length - 1
          return (
            <li key={idx}>
              <LongTextWithLabel
                label={clause_title}
                content={explanation}
                icon={'Checkmark'}
                styles={{ icon: { color: SharedColors.green20 } }}
              />
              {isLastItem || <StyledDivider />}
            </li>
          )
        })}
      </UnstyledList>
    )
  }

  function renderNotReviewedList() {
    return (
      <UnstyledList style={{ padding: '0 0.5em 0' }}>
        {notReviewed.map((clause_title, idx) => {
          const isLastItem = idx === notReviewed.length - 1
          return (
            <li key={idx}>
              <Label style={{ margin: '0.5em 0' }}>{clause_title}</Label>
              {isLastItem || <StyledDivider />}
            </li>
          )
        })}
      </UnstyledList>
    )
  }

  function issueHeader({ clause_title, issue_type }: ClauseIssue) {
    const style = issueBadgeStyleMap[issue_type] ?? badgeStyle
    return (
      <span style={{ position: 'relative' }}>
        <BoldText style={{ marginRight: '0.3em' }}>{titleize(clause_title)}</BoldText>
        <TextBadge style={style}>{issue_type}</TextBadge>
      </span>
    )
  }
}
